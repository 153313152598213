<style lang="scss">
.info-snippet-body {
  height: 100%;
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0;

  & > div {
    height: 100%;
    overflow: auto;
  }

  &.apps-cont {
    & > .col-sm-4 {
      float: right;
    }
  }
  .info-snippet-preview {
    padding-left: 0;
    padding-right: 0;
    border-left: solid 1px #eaeaea;

    .info-snippet-img-cont {
      height: 200px;
      background: url("../../../../public/media/bg/farm.jpeg");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      text-align: center;
      padding-top: 40px;

      .pt-value {
        color: #fff;
        margin-top: 10px;
        font-size: 18px;
      }

      .info-snippet-img-holder {
        display: inline-block;
        padding: 2px;
        background: #fff;
        border-radius: 3px;
        position: relative;
        img {
          height: 100px;
          border-radius: 3px;
        }
      }
    }

    .info-snippet-preview-data {
      padding: 15px;
    }
  }
  .info-snippet-form {
    padding: 15px 0;
  }
}
</style>
<template>
  <div class="info-snippet-body apps-cont">
    <div class="col-sm-4 info-snippet-preview apps-preview">
      <div class="info-snippet-img-cont">
        <div>
          <div class="info-snippet-img-holder">
            <div>
              <img :src="require(`@/assets/images/icon/png/${icon}`)" />
            </div>
          </div>
          <div>
            <label class="pt-value">{{ title }}</label>
          </div>
        </div>
      </div>
      <!-- <div class="col-xs-12 info-snippet-preview-data text-center">
        <a class="app-install btn btn-info mailchimp-deactivate">Deactivate</a>
      </div> -->
    </div>
    <div class="col-sm-8">
      <div class="info-snippet-form clearfix ">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  data() {
    return {
      title: "",
      icon: "",
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Integrations",
          route: "client.integrations.index"
        }
      ]
    };
  },
  created() {
    if (this.$route.query.title) {
      this.title = this.$route.query.title;
    }

    if (this.$route.query.icon) {
      this.icon = this.$route.query.icon;
    }

    var add_b_route = {
      id: 3,
      title: this.title,
      route: ""
    };
    this.breadCrumbs.push(add_b_route);
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  }
};
</script>
